@use '../index.sass'

.image, .card-image
    height: 300px
    width:300px
.card
    margin: 40px
    width: 300px
.portfolio
    background-color: index.$primary-darker
    color: index.$bg-light
    text-align: center
    padding: 50px
.portfolio-grid
    display: flex
    flex-direction: row
    flex-wrap: wrap
    justify-content: center
.portfolio-title
    font-size: 88px
    margin-bottom: 24px
@media (max-width: 768px)
    .image, .card-image
        height: 200px
        width: 200px
    .card
        margin: 24px
        width: 200px
    .content
        font-size: 12px
    .title
        font-size: 14px !important
    .portfolio
        margin-top: 0
    .portfolio-title    
        font-size: 32px