@use '../index.sass'

.about-paragraphs
    color: index.$dark-grey
    font-size: 22px
    margin-left: 8%
    margin-top: 20px
    text-align: justify
.hero-textbox
    background-color: index.$bg-light
    padding: 50px



