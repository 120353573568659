@use '../index.sass'

.coded-by
    margin-top: 12px
.icons
    display: flex
    flex-direction: row
    font-size: 38px
    justify-content: space-evenly
    margin: 0 auto
    width: 200px
.contact-title
    font-size: 32px
    font-weight: bold
.footer
    background-color: index.$bg-light
    padding: 32px
    text-align: center
