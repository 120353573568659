$headline-font: 'Sacramento', cursive
$body-font: 'Poppins', sans-serif
$primary-lighter: #8EB8A8
$primary-darker: #719386
$accent-color: #DBA6A6
$accent-lighter: #f9f1f1
$bg-light: #F6F4EF
$dark-grey: #60656F


.a-dark-bg
  color: $bg-light
  font-weight: bold
  text-decoration: underline

.a-dark-bg:hover, .a-dark-bg:visited:hover
  color: $primary-lighter
  font-weight: bold
  text-decoration: underline

.a-dark-bg:visited
  color: lighten($accent-color, 25%)
  font-weight: bold
  text-decoration: none !important


.a-light-bg
  color: $primary-darker
  font-weight: bold
  text-decoration: underline

.a-light-bg:hover, .a-light-bg:visited:hover
  color: $dark-grey
  font-weight: bold
  text-decoration: underline

.a-light-bg:visited
  color: $accent-color
  font-weight: bold
  text-decoration: none !important

.navbar
  background-color: rgba(255,255,255, 0.6)
  position: fixed
  width: 100%

.navbar-item, .navbar-item:visited
  color: $dark-grey
  font-family: $headline-font
  font-size: 20px
  font-weight: bold
  text-decoration: none !important

.navbar-item:hover, .navbar-item:visited:hover
  color: $primary-lighter !important
  font-family: $headline-font
  font-weight: bold
  text-decoration: underline

#navbarItems, .navbar-start
  display: flex !important
  flex-direction: row !important
  align-items: center

body, html
  height: 100%
  margin: 0
  max-width: 100%
  font-family: $body-font

p, h4
  font-family: $body-font
  
h1, h2, h3
  font-family: $headline-font

@media (max-width: 768px)
  .navbar
    display: flex
    font-size: 12px
    min-height: 0
    position: relative
  .navbar img 
    height: 20px
    padding: 0
    width: 20px

